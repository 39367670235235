import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import './Unab.css';


function Unab() {
  const { _page } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(Number(_page || 2));

  const strPage = String(page).padStart(4, '0');

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 37 && page > 2) {
        setPage(page-1);
        navigate(`/unab/${page-1}`);
      } else if (e.keyCode === 39 && page < 400) {
        setPage(page+1);
        navigate(`/unab/${page+1}`);
      }
    }

    window.scrollTo({ top: 0, behavior: 'auto' });

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [page]);

  return (
    <>
      <Helmet>
        <title>{`PdE ${page}`}</title>
      </Helmet>
      <div className="Unab">
        <img src={`/dir_unab/Principios_de_economia/${strPage}.jpg`} alt='page' className='image' />
      </div>
    </>
  );
}

export default Unab;
