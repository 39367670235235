import { Helmet } from 'react-helmet-async';

import './App.css';


function App() {
  return (
    <>
      <Helmet>
        <title>Gabriel Salazar Moreno</title>
      </Helmet>
      <div class="App">
        <img alt="Nothing To Do Here" src="/ntdh.gif" />
      </div>
    </>
  );

}

export default App;
